import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import pageNotFound from "../images/96983-404-error.gif"

const isBrowser = typeof window !== "undefined"

// markup
const NotFoundPage = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  if (isBrowser) {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }
  return (
    <Layout isScrolled={isScrolled}>
      <title>Page Not found</title>
      <div style={{ minHeight: "50vh", textAlign: "center" }}>
        <img src={pageNotFound} className="m-auto mb-[-80px]" />
        <p
          style={{ color: "#1c1e4d", marginTop: -20 }}
          className="text-[25px] "
        >
          The page you are looking for was not found
        </p>
        <Link
          to="/"
          style={{ width: "fit-content" }}
          className="bg-[#1c1e4d] mx-auto my-[50px] flex justify-center items-center text-white text-base md:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl min-h-[50px] md:min-h-[54px] px-[55px] "
        >
          Home
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
